.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.submit-btn {
  box-sizing: border-box;
  width: 360px;
  height: 60px;
  line-height: 60px;
  margin: 57px auto 0;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #ff6600;
  background-color: #fff;
  color: #ff6600;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  cursor: pointer;
}
.submit-btn:hover {
  background-color: #ff6600;
  color: #fff;
}
.el-form {
  text-align: center;
}
.el-form .el-form-item {
  width: 520px;
}
.el-form .el-form-item /deep/ .el-form-item__label {
  float: left;
  padding-left: 19px;
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
}
.el-form .el-form-item /deep/ .el-form-item__label::before {
  content: '';
  margin-right: 0;
}
.el-select {
  width: 100%;
}
.learn_item {
  margin-bottom: 1.0416666666666665vw;
}
 /deep/ .el-input__inner {
  height: 52px;
  border-radius: 4px;
}
 /deep/ .el-input__inner::placeholder {
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #999999;
}
 /deep/ .el-input__inner:focus {
  border: 1px solid #ff6600;
}
